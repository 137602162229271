import React, { useState } from 'react'
import './perfil.css'

import ResetPassword from './resetPassword'
import ResetEmail from './resetEmail'
import VerPresupuestos from './verPresupuestos'
import { ButtonCTA, LinkCTA } from 'Components/buttons'

import { usuario } from "Constants"


const Perfil = (props) => {

    const [perfilSelected, setPerfilSelected] = useState('portada')

    let perfilRender

    if (!usuario) {
        window.location.replace('/login')
    }

    const email = usuario.email
    const colegiado = (usuario.userName).split(" - ")[0]
    const nombre = (usuario.userName).split(" - ")[1]

    const updateSelected = (type) => {
        setPerfilSelected(type)
    }

    switch (perfilSelected) {
        case 'contraseña':
            perfilRender = <ResetPassword clickPortada={() => updateSelected('portada')} handleLoading={props.handleLoading} />
            break;
        case 'email':
            perfilRender = <ResetEmail clickPortada={() => updateSelected('portada')} handleLoading={props.handleLoading} />
            break;
        case 'presupuestos':
            perfilRender = <VerPresupuestos clickPortada={() => updateSelected('portada')} />
            break;


        case 'portada':
            perfilRender =
                <div className='perfil__container'>

                    <div className='perfil__container__details'>
                        <div className='perfil__container__details__sub'>
                            <h3 className='perfil__container__details__sub__tag'>Centro Veterinario</h3>
                            <p className='perfil__container__details__sub__text'>{nombre}</p>
                        </div>
                        <div className='perfil__container__details__sub'>
                            <h3 className='perfil__container__details__sub__tag'>Número de Colegiado</h3>
                            <p className='perfil__container__details__sub__text'>{colegiado}</p>
                        </div>
                        <div className='perfil__container__details__sub'>
                            <h3 className='perfil__container__details__sub__tag'>Email</h3>
                            <p className='perfil__container__details__sub__text'>{email}</p>
                        </div>
                    </div>
                    <div className='perfil__container__buttons'>
                        {usuario.admin ? <LinkCTA link='/admin'>Zona Admin</LinkCTA> : ''}
                        <ButtonCTA isClicked={() => updateSelected('contraseña')}>Cambiar Contraseña</ButtonCTA>
                        <ButtonCTA isClicked={() => updateSelected('email')}>Cambiar Email</ButtonCTA>
                        <ButtonCTA isClicked={() => updateSelected('presupuestos')}>Consultar Presupuestos</ButtonCTA>

                    </div>

                </div>
            break

        default:
            break;
    }




    return (
        <div className='perfil'>

            {perfilRender}

        </div>
    )
}

export default Perfil