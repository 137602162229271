import React from 'react'
import './footer.css'

const footer = () => {

    return (
        <div className='footer'>
            <div className='footer__container'>
                <h3 className='footer__container__header'>dónde trabajamos</h3>
                <p className='footer__container__text'>
                    Nuestra área de trabajo se extiende a lo largo de la Comunidad Valenciana y alrededores.
                    <br/>
                    Sin embargo, no descartamos salidas puntuales a otros lugares.
                </p>
            </div>
            <div className='footer__container'>
                <h3 className='footer__container__header'>nuestro trabajo</h3>
                <p className='footer__container__text'>Realizamos cirugía general y ortopédica en clínicas y hospitales veterinarios.</p>
            </div>
            <div className='footer__container'>
                <h3 className='footer__container__header'>contacto</h3>
                <p className='footer__container__text'>
                Pablo Pereira González<br/>
                <a href='tel:684073267'>(+34) 684 073 267</a><br/>
                Silvia Domínguez Giménez<br/>
                <a href='tel:650604837'>(+34) 650 604 837</a><br/><br/>
                <a href='mailto:info@vetandvet.es'>info@vetandvet.es</a>
                </p>
            </div>
        </div>
    )
}

export default footer