import React, { useState, useEffect } from 'react'
import './searchBar.css'

const SearchBar = (props) => {

    const [inputValue, setInputValue] = useState('')

    const filterLoop = () => {
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById('myInput');
        filter = input.value.toUpperCase();
        ul = document.getElementById("myUL");
        li = ul.getElementsByTagName('li');

        // Loop through all list items, and hide those who don't match the search query
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("button")[0];
            txtValue = a.textContent || a.innerText;
            if (!input.value) {
                li[i].style.display = "none";
            } else if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "block";
            } else {
                li[i].style.display = "none";
            }
        }
    }

    useEffect(() => {
        filterLoop()

    }, [inputValue])


    const filterFunction = (e) => {
        setInputValue(e.target.value)
        // Loop through all list items, and hide those who don't match the search query
        filterLoop()
    }

    const clickedSearch = (nombre, importe, trauma) => {
        props.isClicked(nombre, importe, trauma)
        setInputValue('')
    }



    const optionsTejidos =
        props.data ? Object.entries(props.data.tejidosBlandos).map((item, index) =>
            Object.entries(item[1]).map((it, ind) =>
                <li><button className='search-option' key={it[0]} onClick={() => clickedSearch(it[0], it[1], false)}>{it[0]}</button></li>)
        ) : ''

    const optionsTrauma =
        props.data ? Object.entries(props.data.traumatologia).map((item, index) =>
                <li><button className='search-option' key={item[0]} onClick={() => clickedSearch(item[0], item[1], true)}>{item[0]}</button></li>)
         : ''

    return (
        <>
            <input type="text" id="myInput" onChange={(e) => filterFunction(e)} value={inputValue} autoComplete="off" />
            <ul id="myUL">
                {optionsTejidos}
                {optionsTrauma}
            </ul>

        </>
    )
}

export default SearchBar