import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Portada from "Pages/portada";
import Presupuesto from "Pages/presupuesto";

import LogIn from "Pages/login";
import SignUp from "Pages/signup";
import Perfil from "Pages/perfil";
import Admin from "Pages/admin";
import Casos from "Pages/casosClinicos";
import ForgotPassword from "Pages/forgotPassword";
import Equipo from "Pages/equipo";
import Servicios from "Pages/servicios";
import Contacto from "Pages/contacto"

import { usuario } from "Constants"

import EnConstruccion from "Pages/enConstruccion";

import NavBar from "UI/navBar";
import Footer from "UI/footer";
import WhatsApp from "UI/whatsApp";
import Modal from "UI/modal";
import LoadingSpinner from "UI/loadingSpinner";

function App() {
  const [loading, setLoading] = useState({ state: false, text: "" });

  const toggleLoading = (state, text) => {
    setLoading({ state: state, text: text });
  };

  const route = window.location.href.split("/", 4)[3];

  const userTimer = usuario ? usuario.logoutTimer : "";

  useEffect(() => {
    const now = new Date();
    const timer = new Date(userTimer);

    if (usuario) {
      if (now > timer) {
        logout();
      }
    }
  }, [userTimer, usuario]);

  const logout = () => {
    localStorage.removeItem("userData");
    window.location.reload();
  };


  return (
    <Router>
      <div className="App">
        {loading.state ? (
          <Modal>
            <LoadingSpinner>{loading.text}</LoadingSpinner>
          </Modal>
        ) : (
          ""
        )}
        <NavBar ruta={route ? route : "portada"} logout={logout} />
        <WhatsApp />
        <Switch>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route path="/perfil">
            <Perfil handleLoading={toggleLoading} />
          </Route>
          <Route path="/login">
            <LogIn handleLoading={toggleLoading} />
          </Route>
          <Route path="/signup">
            <SignUp handleLoading={toggleLoading} />
          </Route>
          <Route path="/servicios">
            <Servicios />
            {/* <EnConstruccion /> */}
          </Route>
          <Route path="/presupuesto">
            <Presupuesto handleLoading={toggleLoading} loadingState={loading} />
          </Route>
          <Route path="/equipo">
            <Equipo />
          </Route>
          <Route path="/casos-clinicos">
            <EnConstruccion />
            {/* <Casos handleLoading={toggleLoading} loadingState={loading} /> */}
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/contacto">
            <Contacto />
          </Route>
          <Route path="/">
            <Portada />
          </Route>
        </Switch>
        {/* {pageToRender} */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
