import React, { useState, useEffect } from 'react'
import './servicios.css'


import Categoria from './categoria'


import UrinarioIMG from 'Assets/categorias/urinaria-ns.svg'
import ReproductivaIMG from 'Assets/categorias/reproductiva-ns.svg'
import TraumaIMG from 'Assets/categorias/trauma-ns.svg'
import GastroIMG from 'Assets/categorias/gastro-ns.svg'
import HepaticaIMG from 'Assets/categorias/hepatica-ns.svg'
import MinimaIMG from 'Assets/categorias/minima-ns.svg'
import OcularIMG from 'Assets/categorias/ocular-ns.svg'
import OralIMG from 'Assets/categorias/oral-ns.svg'
import PielIMG from 'Assets/categorias/piel-ns.svg'
import PulmonarIMG from 'Assets/categorias/pulmonar-ns.svg'
import OncologicaIMG from 'Assets/categorias/oncologica-ns.svg'
import OidoIMG from 'Assets/categorias/oido-ns.svg'

const Servicios = () => {



    return (
        <>
            <h2 className="title-servicios">Nuestros servicios</h2>
            <div className="servicios">
                <Categoria
                    name="Cirugía oral"
                    imgSrc={OralIMG}
                    list={["Exodoncias", "Limpiezas de boca", "Paladar hendido", "Mucoceles y fístulas", "Neoplasias"]}

                />
                <Categoria
                    name="Cirugía reproduciva"
                    imgSrc={ReproductivaIMG}
                    list={["Esterilizaciones", "Criptórquidos", "Cesáreas", "Piometras", "Episioplastias"]}
                />
                <Categoria
                    name="Cirugía oncologica"
                    imgSrc={OncologicaIMG}
                    list={["Mastectomías regionales", "Mastectomías completas", "Otras neoplasias", "Biopsias"]}
                />
                <Categoria
                    name="Plástica y reconstructiva"
                    imgSrc={PielIMG}
                    list={["Plastias", "Amputaciones", "Caudectomía terapéutica", "Hernias", "Pinectomía y trufectomía"]}
                />

                <Categoria
                    name="Gastrointestinal"
                    imgSrc={GastroIMG}
                    list={["SDTG (dilatación torsión)", "Gastrotomías/ Grastectomías", "Enterotomías", "Enterectomías", "Gastropexias", "Laparotomía exploratoria", "Esplenectomía", "Colotomía/ Colectomía Subtotal", "Colopexia"]}
                />
                <Categoria
                    name="Ocular"
                    imgSrc={OcularIMG}
                    list={["Enucleación", "Entropión", "Ectropión", "Prolapso de glándula de harder", "Propotosis"]}
                />

                <Categoria
                    name="Sistema urinario"
                    imgSrc={UrinarioIMG}
                    list={["Cistotomía", "Nefrectomía", "Uretrotomía", "Uretrostomía", "Cistopexia"]}
                />
                <Categoria
                    name="Sistema respiratorio"
                    imgSrc={PulmonarIMG}
                    list={["Colocación Pleuracan", "Lateralización aritenoides", "Síndrome braquiocefálico", "Lobectomía pulmonar"]}
                />
                <Categoria
                    name="Hepatobiliar"
                    imgSrc={HepaticaIMG}
                    list={["Hepatectomía Parcial", "Lobectomía hepática", "Colecistectomía", "Colecistoduodenostomía"]}
                />

                <Categoria
                    name="Traumatología"
                    imgSrc={TraumaIMG}
                    list={["Fracturas", "Rotura Ligamento Cruzado", "(Extracapsular, TPLO, CTWO)", "Luxación de rótula", "Artroplastias", "Infiltraciones", "Artrocentésis"]}
                />


                <Categoria
                    name="Mínima invasión"
                    imgSrc={MinimaIMG}
                    list={["Ovariectomía laparoscopica", "Ovario remanente", "Criptórquidos abdominales", "Gastropexia", "Rinoscopia", "Vaginocistoscopia", "Laparoscopia exploratoria", "Biopsias", "Colecistectomía"]}
                />

                <Categoria
                    name="Oído"
                    imgSrc={OidoIMG}
                    list={["OVBT", "TECA-LBO", "Pinectomía terapéutica", "Otohematomas"]}
                />

            </div>
        </>
    )
}

export default Servicios