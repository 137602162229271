import React, { useEffect } from 'react'
import { geoJSON } from "./utils"

import Map from 'ol/Map';
import View from 'ol/View';
import OSM from 'ol/source/OSM';
import { Vector as VectorSource } from 'ol/source';

import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import GeoJSON from 'ol/format/GeoJSON';


import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';






import './styles/styles.css'

const Contacto = () => {


    const styles = {
        'MultiPolygon': new Style({
            stroke: new Stroke({
                color: 'rgb(88, 116, 122)',
                width: 2,
            }),
            fill: new Fill({
                color: 'rgba(88, 116, 122, 0.1)',
            }),
        }),
        'GeometryCollection': new Style({
            stroke: new Stroke({
                color: 'rgb(88, 116, 122)',
                width: 2,
            }),
            fill: new Fill({
                color: 'rgba(88, 116, 122,.3)',
            }),
            image: new CircleStyle({
                radius: 10,
                fill: null,
                stroke: new Stroke({
                    color: 'rgb(88, 116, 122)',
                }),
            }),
        }),
    }

    const styleFunction = (feature) => {
        return styles[feature.getGeometry().getType()];
    };

    useEffect(() => {

        const coordCV = [-0.376805, 39.4702]

        var vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(geoJSON),
        });

        var vectorLayer = new VectorLayer({
            source: vectorSource,
            style: styleFunction,
        });



        new Map({
            layers: [
                new TileLayer({ source: new OSM() }),
                vectorLayer
            ],

            view: new View({
                projection: "EPSG:4326",
                center: coordCV,
                zoom: 7.8

            }),


            target: 'map'
        });





    }, [])

    return (
        <div className="contacto">
            <div className='section__title' id='section__title'>
                <h2>Contacta con nosotros para cualquier duda</h2>
            </div>
            <div className="contacto__container">
                <div className="contacto__container__header">Contacto</div>
                <div className="contacto__container__body-1">
                    <div className="contacto__container__body-1__name">
                        <p>Pablo Pereira González</p>
                        <p>(+34) 684 073 267</p>
                    </div>
                    <div className="contacto__container__body-1__name">
                        <p>Silvia Domínguez Giménez</p>
                        <p>(+34) 650 604 837</p>
                    </div>
                    <div className="contacto__container__body-1__name">
                        <a href="mailto:info@vetandvet.es">
                            info@vetandvet.es
                        </a>
                    </div>
                </div>
            </div>
            <div className="contacto__container">
                <div className="contacto__container__header-2">Área de trabajo</div>
                <div className="contacto__container__subheader">(Comunidad Valenciana y alrededores)</div>

                <div className="contacto__container__body-2">
                    <div id="map" ></div>

                </div>
            </div>


        </div>
    )
}

export default Contacto