import React, { useEffect, useState } from 'react'

import axios from 'axios'

import { generarPDF } from 'UI/generarPDF'
import { DisplayPresupuestoMin } from 'UI/displayPresupuesto'



import { baseURL, usuario } from 'Constants'
import { ButtonCTA } from 'Components/buttons'


const VerPresupuestos = ({ userName, callback, ...props }) => {

    const [data, setData] = useState()

    useEffect(() => {
        axios.get(`${baseURL}/users/${userName}/presupuestos.json?auth=${usuario.token}`)
            .then(res => {
                setData(res.data)
            })
            .catch(err => console.log(err))
    }, [])

    const noPresupuestos = <div>
        <h2>
            Todavía no ha descargado ningún presupuesto
        </h2>
    </div>

    const pdfSVG = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 482.1 482.1" className='pdfSVG' ><path d="M142 310.2c0-8-5.6-12.8-15.4-12.8 -4 0-6.7 0.4-8.1 0.8v25.7c1.7 0.4 3.7 0.5 6.6 0.5C135.6 324.4 142 319.1 142 310.2z" /><path d="M202.7 297.7c-4.4 0-7.2 0.4-8.9 0.8v56.9c1.7 0.4 4.4 0.4 6.8 0.4 17.8 0.1 29.4-9.7 29.4-30.4C230.2 307.2 219.6 297.7 202.7 297.7z" /><path d="M315.5 0H121.8c-28.3 0-51.3 23-51.3 51.3v189.8h-5c-11.4 0-20.7 9.3-20.7 20.7v125.4c0 11.4 9.3 20.7 20.7 20.7h5v23c0 28.3 23 51.3 51.3 51.3h264.2c28.3 0 51.3-23 51.3-51.3V121.4L315.5 0zM99.1 284.4c6.1-1 14.6-1.8 26.6-1.8 12.1 0 20.8 2.3 26.6 7 5.5 4.4 9.3 11.6 9.3 20.1 0 8.5-2.8 15.7-8 20.6 -6.7 6.3-16.6 9.2-28.3 9.2 -2.6 0-4.9-0.1-6.7-0.4v31.1H99.1V284.4zM386 450.7H121.8c-11 0-19.9-8.9-19.9-19.9v-23h246.3c11.4 0 20.7-9.2 20.7-20.7V261.7c0-11.4-9.3-20.7-20.7-20.7h-246.3V51.3c0-10.9 8.9-19.9 19.9-19.9l181.9-0.2v67.2c0 19.6 15.9 35.6 35.6 35.6l65.9-0.2 0.7 296.9C405.9 441.8 397 450.7 386 450.7zM174.1 369.8v-85.4c7.2-1.1 16.6-1.8 26.6-1.8 16.5 0 27.2 3 35.6 9.3 9 6.7 14.7 17.4 14.7 32.8 0 16.6-6.1 28.1-14.5 35.2 -9.2 7.6-23.1 11.2-40.1 11.2C186.2 371.1 179 370.4 174.1 369.8zM314.9 319.2v16h-31.2v35h-19.7v-87h53.2v16.1h-33.4v19.9H314.9z" /></svg>

    const ojoSVG = <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className='ojoSVG'><title>Eye</title><path d='M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 00-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 000-17.47C428.89 172.28 347.8 112 255.66 112z' fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' /><circle cx='256' cy='256' r='80' fill='none' strokeMiterlimit='10' strokeWidth='32' /></svg>

    const togglePresupuesto = (id) => {
        const doc = document.getElementById(id)
        doc.classList.contains('hidden') ? doc.classList.remove('hidden') : doc.classList.add('hidden')
    }


    return (
        <div className='verPresupuestos'>
            <div className='verPresupuestos__presupuestos-container'>
                {data ? Object.entries(data).reverse().map((item, index) => {
                    return (
                        <div className='verPresupuestos__presupuestos-container__item' key={index}>
                            <div className='verPresupuestos__presupuestos-container__item__sub'>
                                <div className='verPresupuestos__presupuestos-container__item__text'>
                                    <p>
                                        Presupuesto {item[0].split('_')[0]}
                                    </p>
                                    <p>
                                        Total Factura: {item[1].total}€
                                    </p>
                                </div>
                                <div className='verPresupuestos__presupuestos-container__item__buttons'>
                                    <button onClick={() => generarPDF(item[1].presupuesto, item[1].trauma, usuario)}>{pdfSVG}</button>
                                    <button onClick={() => togglePresupuesto(item[0].split('__')[1])}>{ojoSVG}</button>
                                </div>
                            </div>
                            <div className='togglePresupuesto hidden' id={item[0].split('__')[1]}>
                                <DisplayPresupuestoMin presupuesto={item[1].presupuesto} />
                            </div>

                        </div>
                    )
                }) : noPresupuestos}
            </div>
            <div className='verPresupuestos__presupuestos-button'>
                <ButtonCTA isClicked={callback}>Volver</ButtonCTA>
            </div>

        </div>
    )
}

export default VerPresupuestos