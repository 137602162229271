import React from 'react'
import './loadingSpinner.css'

const loadingSpinner = (props) => {

    return (
        <>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            <p>{props.children}</p>
        </>
    )
}

export default loadingSpinner