import React from 'react'
import './equipo.css'

import FotoPablo from 'Assets/fotosEquipo/pablo.jpg'
import FotoSilvia from 'Assets/fotosEquipo/silvia.jpg'


const equipo = () => {

    return (
        <div className='equipo'>
            <div className='section__title' id='section__title'>
                <h2>Deja que nos presentemos</h2>
            </div>
            <div className="equipo__container">
                <img className="equipo__container__img" src={FotoSilvia} alt="Foto Silvia" />
                <div className="equipo__container__sub">
                    <p className="equipo__nombre">SILVIA DOMÍNGUEZ GIMÉNEZ</p>
                    <p className="equipo__titulo">Cofundadora</p>
                    <ul className="equipo__container__list">
                        <li className="equipo__container__list__element">
                            Licenciada en Veterinaria por la Universidad Cardenal Herrera CEU de Valencia en 2016
                        </li>
                        <li className="equipo__container__list__element">
                            Curso Superior de Posgrado Universitario en Cirugía de Tejidos Blandos 2017
                        </li>
                        <li className="equipo__container__list__element">
                            Curso Superior de Posgrado Universitario en Medicina Interna pequeños animales 2018 (Colaboró en la organización y desarrollo)
                        </li>
                        <li className="equipo__container__list__element">
                            Entre 2016 y 2017 – Internado Rotatorio en Pequeños Animales en el Hospital Clínico Veterinario CEU
                        </li>
                        <li className="equipo__container__list__element">
                            Entre 2017 y 2018 – Internado Especialidad (Cirugía, Cardiología y UCI) , pequeños animales.
                        </li>
                        <li className="equipo__container__list__element">
                            Miembro de AVEPA (Asociación de Veterinarios Especialistas en Pequeños Animales)
                        </li>
                        <li className="equipo__container__list__element">
                            Miembro de GECAR (Grupo de Cardiología y Aparato Respiratorio)
                        </li>
                        <li className="equipo__container__list__element">
                            Miembro de SECIVE (Sociedad Española de Cirugía Veterinaria)
                        </li>
                    </ul>
                </div>
            </div>
            <div className="equipo__container">
                <img className="equipo__container__img" src={FotoPablo} alt="Foto Pablo" />
                <div className="equipo__container__sub">
                    <p className="equipo__nombre">PABLO PEREIRA GONZÁLEZ</p>
                    <p className="equipo__titulo">Cofundador</p>
                    <ul className="equipo__container__list">
                        <li className="equipo__container__list__element">
                            Licenciado en Veterinaria por la Universidad Cardenal Herrera CEU de Valencia en 2016
                        </li>
                        <li className="equipo__container__list__element">
                            Curso Superior de Posgrado Universitario en Cirugía de Tejidos Blandos 2017
                        </li>
                        <li className="equipo__container__list__element">
                            Curso Superior de Posgrado Universitario en Traumatología pequeños animales 2019
                        </li>
                        <li className="equipo__container__list__element">
                            Entre 2016 y 2017 – Internado Rotatorio en Pequeños Animales en el Hospital Clínico Veterinario CEU
                        </li>
                        <li className="equipo__container__list__element">
                            Entre 2017 y 2018 – Internado Especialidad (Cirugía, Traumatología y Ortopedia)
                        </li>
                        <li className="equipo__container__list__element">
                            Miembro de AVEPA (Asociación de Veterinarios Especialistas en Pequeños Animales)
                        </li>
                        <li className="equipo__container__list__element">
                            Miembro de SECIVE (Sociedad Española de Cirugía Veterinaria)
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default equipo