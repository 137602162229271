import axios from 'axios'
import React, { useState } from 'react'

import { ButtonCTA } from 'Components/buttons'
import ErrorMessage from 'UI/errorMessage'

import { apiKey, usuario } from 'Constants'

const ForgotPassword = () => {

    const [email, setEmail] = useState()
    const [error, setError] = useState({ state: false, text: '' })

    const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    const isEmailValid = email ? emailPattern.test(email) : false

    if (usuario) {
        window.location.replace('/perfil')
    }

    const handleEmailReset = (e) => {
        e.preventDefault()
        axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${apiKey}`, {
            requestType: "PASSWORD_RESET",
            email: email
        })
        .then(() => setError({state: true, text: 'passwordReset'}))
        .catch(err => setError({state: true, text: err.response.data.error.message}))
    }
    


    return (
        <div className='login'>

            <div className='section__title'>
                <h2>Recibirás un email para reiniciar tu contraseña</h2>
            </div>

            <form className='login__form'>
                <div className='login__form__container'>
                    <label className='login__form__label' htmlFor='email-field'>introduce tu email</label>
                    <input className='login__form__input' onChange={(event) => setEmail(event.target.value)} id='email-field' type='email' placeholder='tuemail@ejemplo.com' />
                </div>

                {error.state ? <ErrorMessage errorMessage={error.text} /> : ''}
                <ButtonCTA isClicked={(e) => handleEmailReset(e)} isDisabled={!isEmailValid}>reiniciar contraseña</ButtonCTA>


            </form>
        </div>
    )
}

export default ForgotPassword