import React, { useEffect } from 'react'
import './slideShow.css'

import {ReactComponent as FlechaCarrusel} from 'Assets/botones/flecha.svg'




const SlideShow = () => {

    let slideIndex = 1;


    // Next/previous controls
    const plusSlides = n => {
        showSlides(slideIndex += n);
    }

    // Thumbnail image controls
    const currentSlide = n => {
        showSlides(slideIndex = n);
    }



    useEffect(() => {
        showSlides(slideIndex);
    })

    const showSlides = (n) => {
        let slides = [document.getElementById('slide-1'), document.getElementById("slide-2"), document.getElementById("slide-3")]
        let dots = document.getElementsByClassName("dot");
        let i;
        if (n > slides.length) { slideIndex = 1 }
        if (n < 1) { slideIndex = slides.length }
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }
        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";
    }


    return (
        <>
            <div className="slideshow-container">

                {/* <!-- Full-width images with number and caption text --> */}
                <div className="mySlides fade" id='slide-1'>
                    <a className='portada__imagen__boton-central' href='/presupuesto'>Presupuesto</a>
                </div>

                <div className="mySlides fade" id='slide-2'>
                    <a className='portada__imagen__boton-central' href='/servicios'>Servicios</a>
                </div>

                <div className="mySlides fade" id='slide-3'>
                    <a className='portada__imagen__boton-central' href='/casos-clinicos'>Casos clínicos</a>
                </div>

                {/* <!-- Next and previous buttons --> */}
                <button className="prev" onClick={() => plusSlides(-1)}>
                    {/* <img src={flechaCarrusel} alt='flecha atras' /> */}
                    <FlechaCarrusel/>
                    </button>
                <button className="next" onClick={() => plusSlides(1)}>
                    {/* <img src={flechaCarrusel} alt='flecha adelante' /> */}
                    <FlechaCarrusel/>
                    </button>
                <div className="dot__container">
                    <span className="dot" onClick={() => currentSlide(1)}></span>
                    <span className="dot" onClick={() => currentSlide(2)}></span>
                    <span className="dot" onClick={() => currentSlide(3)}></span>
                </div>
            </div>


            {/* <!-- The dots/circles --> */}

        </>
    )
}

export default SlideShow