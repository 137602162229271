import React from 'react'
import './categoria.css'



const categoria = (props) => {


   const selectValue = props.seleccionado[props.tipo] ? props.seleccionado[props.tipo].nombre : ''
   

    const options = props.listado ? Object.entries(props.listado).map((item, index) =>
        <option className='presupuesto__add-container__selectable__option' key={[item[0], item[1]]} value={item[1]}>{item[0]}</option>)
        : ''

    return (
        <div className='categoria'>
            <div className='categoria__imagen'>

                <img src={props.source} alt={`${props.nombre}-imagen`} />   
            </div>
            <div className='categoria__titulo'>
                <p>{props.nombre}</p>
            </div>
            <div className='categoria__select'>
                <select defaultValue={selectValue} onChange={(e) => props.handleOnChange(e, props.tipo, props.isTrauma)} >
                    <option value='' disabled hidden></option>
                    {options}
                </select>
                <button disabled={!props.seleccionado[props.tipo]} className="presupuesto__add-container__button" onClick={() => props.handleOnClick(props.tipo, props.isTrauma)}>
                    <svg xmlns='http://www.w3.org/2000/svg' className='ionicon' viewBox='0 0 512 512'><title>Add</title><path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' d='M256 112v288M400 256H112' /></svg>
                </button>
            </div>
        </div>
    )
}

export default categoria