import React from 'react'
import './errorMessage.css'

const errorMessage = (props) => {

    let errorShow
    let errorClass = "errorMessage red"

    switch (props.errorMessage) {
        case 'EMAIL_NOT_FOUND':
            errorShow = 'Usuario no registrado'
            errorClass = "errorMessage red"
            break;
        case 'INVALID_PASSWORD':
            errorShow = 'Contraseña incorrecta'
            errorClass = "errorMessage red"
            break
        case 'EMAIL_EXISTS':
            errorShow = 'Email ya registrado'
            errorClass = "errorMessage red"
            break;
        case 'userCreated':
            errorShow = 'Usuario creado correctamente'
            errorClass = "errorMessage green"
            break;
        case 'passwordReset':
            errorShow = 'Email enviado, revisa tu bandeja de entrada y spam'
            errorClass = "errorMessage green"
            break;
        default:
            break;
    }
    return (
        <>
            <p className={errorClass}>{errorShow}</p>
        </>
    )
}

export default errorMessage