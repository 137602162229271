import React from 'react'
import './enConstruccion.css'

import { LinkCTA } from 'Components/buttons'

const enConstruccion = () => {

    return (
        <>
            <div className='section__title' id='section__title'>
                <h2>Esta sección está en construcción</h2>
            </div>
            <div className='unAuth__sub__container unAuth'>
                <div className='unAuth__sub__container__item'>
                    <LinkCTA link='/'>Volver a la portada</LinkCTA>
                </div>
            </div>
        </>
    )
}

export default enConstruccion