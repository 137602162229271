import React from 'react'
import './unAuth.css'

import { LinkCTA } from 'Components/buttons'


const unAuth = (props) => {

    if (props.admin) {
        return (
            <div className='unAuth'>
                <div className='section__title'>
                    <h2>No tienes acceso a esta sección</h2>
                </div>
                <div className='unAuth__sub__container unAuth'>
                    <div className='unAuth__sub__container__item'>
                        <LinkCTA link='/'>Volver a la portada</LinkCTA>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className='unAuth'>
            <div className='section__title'>
                <h2>Entra o regístrate para poder ver esta sección</h2>
            </div>
            <div className='unAuth__sub__container unAuth'>
                <div className='unAuth__sub__container__item '>
                    <h2>¿Ya tienes cuenta?</h2>
                    <LinkCTA link='/login' >Entra</LinkCTA>
                </div>
                <div className='unAuth__sub__container__item'>
                    <h2>¿Quieres crearte una cuenta?</h2>
                    <LinkCTA link='/signup' >Registrarse</LinkCTA>

                </div>

            </div>

        </div>
    )
}

export default unAuth