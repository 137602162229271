import React, { useState, useEffect } from 'react'

import axios from 'axios'
import { apiKey, baseURL } from 'Constants'

import { ButtonCTA } from 'Components/buttons'

import ErrorMessage from 'UI/errorMessage'


const SignUp = (props) => {

    const [signUpDetails, setSignUpDetails] = useState({ email: '', contraseña: '', nombre: '', numero: '' })
    const [error, setError] = useState({state: false, text: ''})


    const handleUpdate = (e, type) => {
        setSignUpDetails({ ...signUpDetails, [type]: e.target.value })
    }

    const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    const isEmailValid = signUpDetails.email ? emailPattern.test(signUpDetails.email) : false
    const isPasswordValid = signUpDetails.contraseña ? signUpDetails.contraseña.length >= 6 : false
    const isCentroValid = signUpDetails.nombre ? signUpDetails.nombre.length >= 3 : false
    const isNumeroValid = signUpDetails.numero ? signUpDetails.numero.length >= 1 : false


    useEffect(() => {
        if (!isEmailValid && signUpDetails.email.length > 0) {
            const element = document.getElementById('email-field')
            element.classList.remove("complete")
            element.classList.add("incomplete")
        } else if (isEmailValid) {
            const element = document.getElementById('email-field')
            element.classList.remove("incomplete")
            element.classList.add("complete")
        }

        if (!isCentroValid && signUpDetails.nombre.length > 0) {
            const element = document.getElementById('centro-field')
            element.classList.remove("complete")
            element.classList.add("incomplete")
        } else if (isCentroValid) {
            const element = document.getElementById('centro-field')
            element.classList.remove("incomplete")
            element.classList.add("complete")
        }

        if (!isNumeroValid && signUpDetails.numero.length > 0) {
            const element = document.getElementById('numero-field')
            element.classList.remove("complete")
            element.classList.add("incomplete")
        } else if (isNumeroValid) {
            const element = document.getElementById('numero-field')
            element.classList.remove("incomplete")
            element.classList.add("complete")
        }

        if (!isPasswordValid && signUpDetails.contraseña.length > 0) {
            const element = document.getElementById('password-field')
            element.classList.remove("complete")
            element.classList.add("incomplete")
        } else if (isPasswordValid) {
            const element = document.getElementById('password-field')
            element.classList.remove("incomplete")
            element.classList.add("complete")
        }

    }, [isEmailValid, isPasswordValid, isCentroValid, isNumeroValid, signUpDetails])


    const signUp = (e) => {
        e.preventDefault()
        props.handleLoading(true, 'Creando usuario')
        const fileDoc = document.getElementById('file-upload').files[0]

        const userName = signUpDetails.numero + ' - ' + signUpDetails.nombre

        //Transform to B64
        var reader = new FileReader();
        reader.onloadend = function () {
        }
        reader.readAsDataURL(fileDoc);

        axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${apiKey}`, {
            email: signUpDetails.email,
            password: signUpDetails.contraseña,
            returnSecureToken: true
        })
            .then(res => {
                axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:update?key=${apiKey}`, {
                    idToken: res.data.idToken,
                    displayName: userName
                })
                    .then(() => {
                        sendSignupConfirmation()
                        sendEmail(reader.result.split(',')[1])
                        setError({state: true, text: "userCreated"})
                        axios.put(`${baseURL}users/${userName}.json?auth=${res.data.idToken}`, {
                            presupuestos: 0
                        })


                    })
                    .catch(error => console.log(error))

            })
            .catch(err => {
                setError({state: true, text: err.response.data.error.message})
                props.handleLoading(false, '')
            })
    }

    const sendSignupConfirmation = () => {
        window.Email.send({
            SecureToken: "4e99ca7f-94b7-41cb-81fd-c70a780d51b2",
            To: signUpDetails.email,
            From: "admin@vetandvet.es",
            Subject: `Tu registro en Vet & Vet`,
            Body: `
            <div>
                <h1>¡Bienvenido, ya formas parte de la familia Vet & Vet!</h1>
                <ul>
                    <li>Centro Veterinario: ${signUpDetails.nombre}</li>
                    <li>Número de Colegiado: ${signUpDetails.numero}</li>
                    <li>Email: ${signUpDetails.email}</li>
                </ul>
                <br />
                            
                <a style="color:white; background-color:#58747a; text-decoration: none; padding: 10px 20px; font-size: 16px" href='vetandvet.es/login'>Ir a vetandvet.es</a>
                
                <p>Muchas gracias por tu confianza.</p>
                <p>Un saludo,</p>
                <p>Vet & Vet</p>
                
                
            </div>
            `
        })
    }



    const sendEmail = (data) => {

        window.Email.send({
            SecureToken: "4e99ca7f-94b7-41cb-81fd-c70a780d51b2",
            To: 'admin@vetandvet.es',
            From: "admin@vetandvet.es",
            Subject: `Nuevo Usuario - ${signUpDetails.nombre}`,
            Body: `
                            <div>
                                <h2>Nuevo Usuario Registrado</h2>
                                <ul>
                                    <li>Centro Veterinario: ${signUpDetails.nombre}</li>
                                    <li>Número de Colegiado: ${signUpDetails.numero}</li>
                                    <li>Email: ${signUpDetails.email}</li>
                                </ul>
                            </div>
                            `,
            Attachments: [{
                name: 'Carnet Colegiado_' + signUpDetails.numero + '.' + signUpDetails.foto.split('.')[1],
                data: data
            }]
        })
            .then(res => props.handleLoading(false, ''))
            .catch(err => console.log(err))



    }



    return (
        <div className='login'>
            <div className='section__title' >
                <h2>Introduce tus datos para registrarte</h2>
            </div>


            <form className='login__form'>
                <div className='login__form__container'>
                    <label className='login__form__label' htmlFor='email-field'>Email</label>
                    <input className='login__form__input' id='email-field' onChange={(event) => handleUpdate(event, 'email')} type='email' />
                </div>
                <div className='login__form__container'>
                    <label className='login__form__label' htmlFor='password-field'>Contraseña</label>
                    <input className='login__form__input' id='password-field' onChange={(event) => handleUpdate(event, 'contraseña')} type='password' />
                </div>
                <div className='login__form__container'>
                    <label className='login__form__label' htmlFor='centro-field'>Centro Veterinario</label>
                    <input className='login__form__input' id='centro-field' onChange={(event) => handleUpdate(event, 'nombre')} type='text' />
                </div>
                <div className='login__form__container'>
                    <label className='login__form__label' htmlFor='numero-field'>Número de colegiado</label>
                    <input className='login__form__input' id='numero-field' onChange={(event) => handleUpdate(event, 'numero')} type='text' />
                </div>
                <div className='login__form__container'>
                    <label className='login__form__label' htmlFor='file-upload'>Sube aquí tu carnet de colegiado</label>
                    <input className='file-upload' name="file-upload" id='file-upload' type="file" accept='image/*' onChange={(event) => handleUpdate(event, 'foto')} />
                </div>
                {error.state ? <ErrorMessage errorMessage={error.text} /> : ''}
                <ButtonCTA isClicked={(e) => signUp(e)} isDisabled={!(isEmailValid && isPasswordValid && isNumeroValid && isCentroValid && signUpDetails.foto)}>Registrarse</ButtonCTA>

            </form>
        </div>
    )
}

export default SignUp