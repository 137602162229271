import React, { useState } from 'react'
import './resetPassword.css'

import axios from 'axios'

import { apiKey, usuario } from 'Constants'
import { ButtonCTA } from 'Components/buttons'


const ResetPassword = (props) => {

    const [passwordInfo, setPasswordInfo] = useState({})

    const isPasswordActualValid = passwordInfo.actual ? passwordInfo.actual.length >= 6 : false
    const isPasswordNuevoValid = passwordInfo.nueva ? passwordInfo.nueva.length >= 6 : false

    const handleUpdate = (e, type) => {
        setPasswordInfo({ ...passwordInfo, [type]: e.target.value })
    }

    const auth = (email, password) => {
        axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${apiKey}`, {
            email: email,
            password: password,
            returnSecureToken: true
        })
            .then(res => {
                axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:update?key=${apiKey}`, {
                    idToken: res.data.idToken,
                    password: passwordInfo.nueva,
                    returnSecureToken: true
                }).then(respuesta => {
                    if (res.data.localId === 'lJKWgBfqMjaPVmebXnKJm7EAUCE3') {
                        window.localStorage.setItem('userData', JSON.stringify({ token: res.data.idToken, userId: res.data.localId, userName: res.data.displayName, email: res.data.email, admin: true }))
                    } else {
                        window.localStorage.setItem('userData', JSON.stringify({ token: res.data.idToken, userId: res.data.localId, userName: res.data.displayName, email: res.data.email }))
                    }
                    props.handleLoading(false, '')
                    window.location.replace('/perfil')
                }).catch(err => console.log(err))

            })
            .catch(err => console.log(err))


    }

    const reset = (event) => {
        props.handleLoading(true , 'Cambiando contraseña')
        event.preventDefault()
        auth(usuario.email, passwordInfo.actual)
    }



    return (
        <>
            <div className='reset-password'>
                <div className='reset-password__input-container'>
                    <label htmlFor='passwordCurrent'>Contraseña Actual</label>
                    <input onChange={(e) => handleUpdate(e, 'actual')} id='passwordCurrent' type='password' />
                    <label htmlFor='passwordChange'>Nueva contraseña</label>
                    <input onChange={(e) => handleUpdate(e, 'nueva')} id='passwordChange' type='password' />
                </div>
                <div className='reset-password__button-container'>
                    <ButtonCTA isClicked={props.clickPortada}>Volver</ButtonCTA>
                    <ButtonCTA isClicked={(e) => reset(e)} isDisabled={!(isPasswordActualValid && isPasswordNuevoValid)}>Actualizar Contraseña</ButtonCTA>

                </div>
            </div>

        </>
    )
}

export default ResetPassword