import React from 'react'
import './portada.css'

import SlideShow from 'UI/slideShow'
import { LinkCTA } from 'Components/buttons'
import InstaFeed from 'UI/instaFeed'





const Portada = () => {



    return (
        <div className='portada'>
            <SlideShow />
            <div className='portada__sub'>
                <div className='portada__sub__container'>
                    <div className='portada__sub__container__item'>
                        {/* <h2>consulta nuestros servicios</h2> */}
                        <LinkCTA link='/servicios'>consulta nuestros servicios</LinkCTA>
                    </div>
                    <div className='portada__sub__container__item'>
                        {/* <h2>consulta tu presupuesto</h2> */}
                        <LinkCTA link='/presupuesto'>crea tu presupuesto</LinkCTA>
                    </div>
                </div>
                {/* <InstaFeed /> */}
            </div>

        </div >
    )
}

export default Portada