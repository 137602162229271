import React, { useEffect, useState } from 'react'
import './styles/styles.css'

import { baseURL, usuario } from 'Constants'

import VerPresupuesto from './verPresupuesto'

import axios from 'axios'
import { ButtonCTA } from 'Components/buttons'

const ManageUsers = ({ callback, selectUser, ...props }) => {

    const [usersData, setUsersData] = useState()

    useEffect(() => {
        axios.get(`${baseURL}/users.json?auth=${usuario.token}`)
            .then((res) => setUsersData(res.data))

    }, [])

    const ojoSVG = <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className='ojoSVG'><path d='M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 00-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 000-17.47C428.89 172.28 347.8 112 255.66 112z' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' /><circle cx='256' cy='256' r='80' strokeMiterlimit='10' strokeWidth='32' /></svg>





    return (
        <div className="manageUsers">
            <div className="manageUsers__container">
                {usersData ? Object.entries(usersData).map((item) => {
                    return (
                        <div className="manageUsers__userDetail">
                            <div className="manageUsers__userDetail__header">
                                <h3>{item[0]}</h3>
                                <button onClick={() => selectUser(item[0])}>{ojoSVG}</button>
                            </div>
                            <p>Presupuestos descargados: {Object.entries(item[1].presupuestos).length}</p>
                        </div>)
                }) : ""}
                <ButtonCTA isClicked={callback}>Volver</ButtonCTA>
            </div>
        </div>
    )
}

export default ManageUsers