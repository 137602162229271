import { jsPDF } from "jspdf";
import { font } from "Assets/fonts/Poppins-Regular-normal";
import { logoB64 } from "Constants";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const generarPDF = (presupuesto, trauma, usuario) => {
  const pdf = new jsPDF("p", "px", "a4", false, false, 16, 1.0, [
    "px_scaling",
    "ASCIIHexEncode",
  ]);

  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  today = dd + "-" + mm + "-" + yyyy;

  const baseImponible = Object.entries(presupuesto)
    .map((item, index) => {
      let importeItem;
      importeItem = +item[1].importe;
      return importeItem;
    })
    .reduce((a, b) => a + b, 0);

  const iva = (baseImponible * 0.21).toFixed(2);
  const irpf = (baseImponible * 0.15).toFixed(2);
  const totalFactura = parseFloat(baseImponible)
    // parseFloat(iva) -
    // parseFloat(irpf)
    .toFixed(2);

  pdf.addFileToVFS("Poppins-Regular.ttf", font);
  pdf.addFont("Poppins-Regular.ttf", "Poppins-Regular", "normal");
  pdf.setFont("Poppins-Regular", "normal");

  // pdf.addImage(logoB64, 'JPEG', 149.125, 30, 151.75, 48.5, 'Logo', 'NONE', 0)

  pdf.addImage(logoB64, "JPEG", 30, 30, 151.75, 48.5, "Logo", "NONE", 0);

  pdf.setFontSize(13);

  pdf.text(280, 62, "Fecha presupuesto");
  pdf.text(319, 77, today);

  let textHeight = 115;

  Object.entries(presupuesto).forEach((item, index) => {
    pdf.text(
      30,
      textHeight + 15 * index,
      `${capitalizeFirstLetter(item[1].nombre)}`
    );
    pdf.text(350, textHeight + 15 * index, `${item[1].importe}€`);
  });

  textHeight = 115 + (presupuesto.length - 1) * 15;

  pdf.setTextColor("#808080");
  pdf.setFontSize(12);
  pdf.text(60, (textHeight += 15), "Todas nuestras cirugías incluyen:");
  pdf.text(70, (textHeight += 15), "Fungible");
  pdf.text(70, (textHeight += 15), "Equipo electrocirugía");
  pdf.text(70, (textHeight += 15), "Equipo aspiración");
  pdf.text(70, (textHeight += 15), "Material general");

  if (trauma) {
    pdf.text(70, (textHeight += 15), "Implantes");
  }
  pdf.setTextColor("#000000");

  pdf.line(25, (textHeight += 10), 420, textHeight, "S");

  pdf.setFontSize(16);
  // pdf.text(70, (textHeight += 20), `Base Imponible`);
  // pdf.text(350, textHeight, `${baseImponible}€`);
  // pdf.text(70, (textHeight += 20), `IVA`);
  // pdf.text(350, textHeight, `${iva}€`);
  // pdf.text(70, (textHeight += 20), `IRPF`);
  // pdf.text(342, textHeight, "-");
  // pdf.text(350, textHeight, `${irpf}€`);
  pdf.text(70, (textHeight += 20), `Total presupuesto*`);
  pdf.text(350, textHeight, `${totalFactura}€`);

  pdf.line(25, (textHeight += 10), 420, textHeight, "S");

  pdf.setFontSize(10);
  pdf.text(
    50,
    (textHeight += 10),
    "En caso de duda, por favor contacta con nosotros en el teléfono +34 684 07 32 67."
  );

  pdf.text(50, (textHeight += 10), "*Impuestos no incluidos.");

  pdf.text(
    50,
    (textHeight += 10),
    "Este presupuesto tiene una validez de un mes."
  );

  pdf.save(`Presupuesto Vet & Vet: ${usuario.userName}`);
};
