import React, { useEffect } from 'react'
import { ReactComponent as LogoSVG } from 'Assets/logo/logoSVG.svg'
import { ReactComponent as FacebookIMG } from 'Assets/rrss/facebook.svg'
import { ReactComponent as InstagramIMG } from 'Assets/rrss/instagram.svg'
import { ReactComponent as MailIMG } from 'Assets/rrss/mail.svg'
import { ReactComponent as TelefonoIMG } from 'Assets/rrss/telefono.svg'
import './navBar.css'

import { usuario } from "Constants"


const NavBar = (props) => {


    useEffect(() => {
        if (props.ruta !== 'admin') {
            const element = document.getElementById(props.ruta)
            if (element !== null) {
                element.classList.add("active")

            }
        }
    })


    const toPortada = () => {
        window.location.replace('/')
    }

    const loginDisplay = usuario ?
        <>
            <a href='/perfil' className='login-container__login logged'>{usuario.userName} </a>
            <button onClick={props.logout} className='login-container__login'>(salir)</button>
        </> :
        <a href='/login' className='login-container__login'>Entrar</a>

    const signupDisplay = usuario ? '' :
        <li id='signup' className='login-container__item'>
            <a href='/signup' className='login-container__login'>Registrarse</a>
        </li>

    const toggleMobileMenu = () => {
        const mobileNav = document.getElementById("mobile-nav")
        mobileNav.classList.toggle("open")
    }

    const menuButton = <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Menu</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-miterlimit='10' stroke-width='32' d='M80 160h352M80 256h352M80 352h352' /></svg>

    return (
        <div className='navBar'>
            <div className="navBar__mobile-menu">
                <button onClick={toggleMobileMenu}>{menuButton}</button>
            </div>
            <div className="navBar__mobile-menu__nav" id="mobile-nav">
                <li className='navBar__nav__item mobile' id='portada' ><a href='/'>Inicio</a></li>
                <li className='navBar__nav__item mobile' id='servicios'><a href='/servicios'>Servicios</a></li>
                <li className='navBar__nav__item mobile' id='presupuesto'><a href='/presupuesto'>Presupuesto</a></li>
                <li className='navBar__nav__item mobile' id='equipo'><a href='/equipo'>Equipo</a></li>
                <li className='navBar__nav__item mobile' id='casos-clinicos'><a href='/casos-clinicos'>Casos clínicos</a></li>
                <li className='navBar__nav__item mobile' id='contacto'><a href='/contacto'>Contacto</a></li>
            </div>


            <div className='navBar__logo-container' onClick={toPortada}>
                <LogoSVG />
            </div>
            <nav className='navBar__nav'>
                <li className='navBar__nav__item' id='portada' ><a href='/'>Inicio</a></li>
                <li className='navBar__nav__item' id='servicios'><a href='/servicios'>Servicios</a></li>
                <li className='navBar__nav__item' id='presupuesto'><a href='/presupuesto'>Presupuesto</a></li>
                <li className='navBar__nav__item' id='equipo'><a href='/equipo'>Equipo</a></li>
                <li className='navBar__nav__item' id='casos-clinicos'><a href='/casos-clinicos'>Casos clínicos</a></li>
                <li className='navBar__nav__item' id='contacto'><a href='/contacto'>Contacto</a></li>

            </nav>


            <div className='navBar__social-container'>

                <div className='login-container'>
                    {signupDisplay}
                    <li id='login' className='login-container__item'>
                        {loginDisplay}
                    </li>

                </div>
                <div className='navBar__social-container__image-container'>
                    <a href='tel:684073267'>
                        <TelefonoIMG />
                    </a>
                </div>
                <div className='navBar__social-container__image-container'>
                    <a href='mailto:info@vetandvet.es'>
                        <MailIMG />
                    </a>
                </div>
                <div className='navBar__social-container__image-container'>
                    <a target='_blank' rel="noreferrer" href='https://www.instagram.com/vetandvet.vs'>
                        <InstagramIMG />
                    </a>
                </div>
                <div className='navBar__social-container__image-container'>
                    <a target='_blank' rel="noreferrer" href='https://www.facebook.com/vetandvet.vlc/'>
                        <FacebookIMG />
                    </a>
                </div>

            </div>

        </div>
    )
}

export default NavBar