import React from 'react'
import './whatsApp.css'
import whatsIMG from 'Assets/rrss/whatsapp.png'

const whatsApp = () => {

    const openWhatsapp = () => {
        window.open('https://wa.me/34684073267?text=Hola Pablo.')
    }

    return (
        <div className='whattsApp-container'>
            <button className='whattsApp-container__icon  ' onClick={openWhatsapp}>
                <img src={whatsIMG} alt='whatsApp Icon' />
            </button>
            <div className='whattsApp-container__text'>
                Contacta con nosotros para cualquier duda 🤔
            </div>

        </div>
    )
}

export default whatsApp