import React from "react";
import { ButtonCTA } from "Components/buttons";
import axios from "axios";
import { baseURL, usuario } from "Constants";
import { v4 as uuidv4 } from "uuid";

import "./displayPresupuesto.css";

import { generarPDF } from "../generarPDF";

export const DisplayPresupuestoMain = (props) => {
  const importe = props.importeTotal ? props.importeTotal : null;

  const iva = (importe * 0.21).toFixed(2);
  const irpf = (importe * 0.15).toFixed(2);
  const totalFactura = parseFloat(importe)
    // parseFloat(iva) -
    // parseFloat(irpf)
    .toFixed(2);

  const sendEmail = () => {
    window.Email.send({
      SecureToken: "4e99ca7f-94b7-41cb-81fd-c70a780d51b2",
      To: "admin@vetandvet.es",
      From: "admin@vetandvet.es",
      Subject: `${usuario.userName} ha descargado un presupuesto`,
      Body: `
                    <div>
                        <h2>Nuevo presupuesto</h2>
                        
                        ${props.presupuesto
                          .map((item, index) => {
                            return `<p style="display: flex; width: 100%; text-transform: capitalize;" >
                        ${item["nombre"]} - ${item["importe"]}€
                    </p>`;
                          })
                          .join("")}
                        
                        <h4>Subtotal: ${importe}€</h4>
                        <p>IRPF: - ${irpf}€</p>
                        <p>IVA: ${iva}€</p>
                        <h3>Total factura: ${totalFactura}€</h3>

                    </div>
                `,
    }).catch((err) => console.log(err));
  };

  const guardarPresupuesto = () => {
    props.handleLoading(true, "Descargando presupuesto");

    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();

    today = dd + "-" + mm + "-" + yyyy;

    const uid = uuidv4().split("-")[0];

    props.scrollPresupuesto();

    axios
      .put(
        `${baseURL}/users/${usuario.userName}/presupuestos/${today}__${uid}.json?auth=${usuario.token}`,
        {
          total: totalFactura,
          presupuesto: props.presupuesto,
          trauma: props.trauma > 0,
        }
      )
      .then(() => {
        generarPDF(props.presupuesto, props.trauma > 0, usuario);
        props.handleLoading(false, "");
        sendEmail();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      className={
        props.presupuesto.length
          ? "presupuesto__display"
          : "presupuesto__display compact"
      }
    >
      <div className="presupuesto__display__cirugias">
        {props.presupuesto.length ? (
          <p className="tu-presupuesto">Tu presupuesto:</p>
        ) : (
          ""
        )}
        {props.presupuesto.length ? (
          props.presupuesto.map((item, index) => {
            return (
              <div className="cirugia" key={[item[0], item[1], index]}>
                <div className="cirugia__nombre-container">
                  <p
                    key={[item[0], item[1], index, "nombre"]}
                    className="cirugia__nombre"
                  >
                    {item["nombre"]}
                  </p>
                </div>

                {usuario.admin === true ? (
                  <input
                    key={[item[0], item[1], index, "precios"]}
                    defaultValue={item["importe"]}
                    onChange={(e) => props.updatePresupuestoAdmin(e, index)}
                  />
                ) : (
                  <p
                    key={[item[0], item[1], index, "precio"]}
                    className={`cirugia__precio ${
                      item["importe"] === "0" ? "consultar" : ""
                    }`}
                  >
                    {item["importe"] === "0"
                      ? "Consultar"
                      : item["importe"] + "€"}
                  </p>
                )}

                <button
                  className="cirugia-quitar"
                  onClick={() => props.handleRemove(index, item["trauma"])}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                      d="M336 256H176"
                    />
                  </svg>
                </button>
              </div>
            );
          })
        ) : (
          <p className="add__cirugias">
            Por favor, añade cirugías a tu presupuesto
          </p>
        )}
      </div>
      {props.presupuesto.length ? (
        <>
          <ul className="presupuesto__display__extras">
            <li>Todas nuestras cirugías incluyen:</li>
            <li>Fungible</li>
            <li>Equipo electrocirugía</li>
            <li>Equipo aspiración</li>
            <li>Material general</li>
            {props.trauma > 0 ? <li>Implantes</li> : ""}
          </ul>
          <div className="presupuesto__display__totales">
            {/* <div className="subtotal__container">
           <p className="subtotal__container__nombre">Base Imponible</p>
           <p className="subtotal__container__importe">{importe}€</p>
         </div>
         <div className="subtotal__container">
           <p className="subtotal__container__nombre">15% IRPF (RETENCIÓN)</p>
           <p className="subtotal__container__importe">-{irpf}€</p>
         </div>
         <div className="subtotal__container">
           <p className="subtotal__container__nombre">21% IVA</p>
           <p className="subtotal__container__importe">{iva}€</p>
         </div> */}
            <div className="subtotal__container">
              <p className="subtotal__container__nombre total">
                Total Presupuesto*
              </p>
              <p className="subtotal__container__importe total">
                {totalFactura}€
              </p>
            </div>
          </div>
          <ul className="presupuesto__display__extras">
            <li>*Impuestos no incluidos.</li>
            <li>Este presupuesto tiene una validez de un mes.</li>
          </ul>
          <ButtonCTA isClicked={guardarPresupuesto}>
            Descargar presupuesto
          </ButtonCTA>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export const DisplayPresupuestoMin = (props) => {
  const baseImponible = Object.entries(props.presupuesto)
    .map((item, index) => {
      let importeItem;
      importeItem = +item[1].importe;
      return importeItem;
    })
    .reduce((a, b) => a + b, 0);

  const iva = (baseImponible * 0.21).toFixed(2);
  const irpf = (baseImponible * 0.15).toFixed(2);
  const totalFactura = (
    parseFloat(baseImponible) +
    parseFloat(iva) -
    parseFloat(irpf)
  ).toFixed(2);

  return (
    <div className="presupuesto__display min">
      <div className="presupuesto__display__cirugias min">
        <p className="tu-presupuesto min">Tu presupuesto:</p>
        {props.presupuesto.map((item, index) => {
          return (
            <div className="cirugia min" key={[item[0], item[1], index]}>
              <div className="cirugia__nombre-container min">
                <p
                  key={[item[0], item[1], index, "nombre"]}
                  className="cirugia__nombre min"
                >
                  {item["nombre"]}
                </p>
              </div>

              <p
                key={[item[0], item[1], index, "precio"]}
                className="cirugia__precio min"
              >
                {item["importe"]}€
              </p>
            </div>
          );
        })}
      </div>

      <ul className="presupuesto__display__extras min">
        <li>Todas nuestras cirugías incluyen:</li>
        <li>Fungible</li>
        <li>Equipo electrocirugía</li>
        <li>Equipo aspiración</li>
        <li>Material general</li>
        {props.trauma > 0 ? <li>Implantes</li> : ""}
      </ul>

      <div className="presupuesto__display__totales min">
        <div className="subtotal__container min">
          <p className="subtotal__container__nombre min">Base Imponible</p>
          <p className="subtotal__container__importe min">{baseImponible}€</p>
        </div>
        <div className="subtotal__container min">
          <p className="subtotal__container__nombre min">
            15% IRPF (RETENCIÓN)
          </p>
          <p className="subtotal__container__importe min">-{irpf}€</p>
        </div>
        <div className="subtotal__container min">
          <p className="subtotal__container__nombre min">21% IVA</p>
          <p className="subtotal__container__importe min">{iva}€</p>
        </div>
        <div className="subtotal__container min">
          <p className="subtotal__container__nombre min total">Total Factura</p>
          <p className="subtotal__container__importe min total">
            {totalFactura}€
          </p>
        </div>
      </div>
    </div>
  );
};
