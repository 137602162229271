import React, { useState, useEffect } from 'react'
import './login.css'

import { ButtonCTA } from 'Components/buttons'
import axios from 'axios'
import { apiKey, usuario } from 'Constants'

import ErrorMessage from 'UI/errorMessage'

const LogIn = (props) => {

    const [loginDetails, setLoginDetails] = useState({ email: '', password: '' })
    const [error, setError] = useState({ state: false, text: '' })

    const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    const isEmailValid = loginDetails.email ? emailPattern.test(loginDetails.email) : false
    const isPasswordValid = loginDetails.password ? loginDetails.password.length >= 6 : false

    if (usuario) {
        window.location.replace('/perfil')
    }

    const auth = (event, email, password) => {
        event.preventDefault()

        var isSafari = window.safari !== undefined;

        props.handleLoading(true, 'Entrando')

        const timer = new Date()
        timer.setHours(timer.getHours() + 1)

        if (isSafari) {

            axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${apiKey}
            `
                // ?nocache=${new Date().getTime()}
                , {
                    email: email,
                    password: password,
                    returnSecureToken: true
                })
                .then(res => {
                    if (res.data.localId === '8sLf5Rv2ezXuVZ1Ij9NYIjglUX63') {
                        window.localStorage.setItem('userData', JSON.stringify({ token: res.data.idToken, userId: res.data.localId, userName: res.data.displayName, logoutTimer: timer, email: res.data.email, admin: true }))
                    } else {
                        window.localStorage.setItem('userData', JSON.stringify({ token: res.data.idToken, userId: res.data.localId, userName: res.data.displayName, logoutTimer: timer, email: res.data.email }))
                    }

                    props.handleLoading(false, '')
                    window.location.replace('/')
                })
                .catch(err => {
                    setError({ state: true, text: err.response.data.error.message })
                    props.handleLoading(false, '')
                })
        } else {
            axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${apiKey}`, {
                email: email,
                password: password,
                returnSecureToken: true
            })
                .then(res => {
                    if (res.data.localId === '8sLf5Rv2ezXuVZ1Ij9NYIjglUX63') {
                        window.localStorage.setItem('userData', JSON.stringify({ token: res.data.idToken, userId: res.data.localId, userName: res.data.displayName, logoutTimer: timer, email: res.data.email, admin: true }))
                    } else {
                        window.localStorage.setItem('userData', JSON.stringify({ token: res.data.idToken, userId: res.data.localId, userName: res.data.displayName, logoutTimer: timer, email: res.data.email }))
                    }

                    props.handleLoading(false, '')
                    window.location.replace('/')
                })
                .catch(err => {
                    setError({ state: true, text: err.response.data.error.message })
                    props.handleLoading(false, '')

                })
        }
    }

    useEffect(() => {
        if (!isEmailValid && loginDetails.email.length > 0) {
            setError({ state: false, text: '' })
            const element = document.getElementById('email-field')
            element.classList.remove("complete")
            element.classList.add("incomplete")
        } else if (isEmailValid) {
            setError({ state: false, text: '' })
            const element = document.getElementById('email-field')
            element.classList.remove("incomplete")
            element.classList.add("complete")
        }
        if (!isPasswordValid && loginDetails.password.length > 0) {
            setError({ state: false, text: '' })
            const element = document.getElementById('password-field')
            element.classList.remove("complete")
            element.classList.add("incomplete")
        } else if (isPasswordValid) {
            setError({ state: false, text: '' })
            const element = document.getElementById('password-field')
            element.classList.remove("incomplete")
            element.classList.add("complete")
        }

    }, [isEmailValid, isPasswordValid, loginDetails])


    const handleUpdate = (e, type) => {
        setLoginDetails({ ...loginDetails, [type]: e.target.value })
    }


    return (
        <div className='login'>

            <div className='section__title'>
                <h2>Introduce tus datos e inicia sesión</h2>
            </div>

            <form className='login__form'>
                <div className='login__form__container'>
                    <label className='login__form__label' htmlFor='email-field'>Introduce tu email</label>
                    <input className='login__form__input' onChange={(event) => handleUpdate(event, 'email')} id='email-field' type='email' placeholder='tuemail@ejemplo.com'
                    // pattern={/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/} 
                    />
                </div>
                <div className='login__form__container'>
                    <label className='login__form__label' htmlFor='password-field'>Introduce tu contraseña</label>
                    <input className='login__form__input' onChange={(event) => handleUpdate(event, 'password')} id='password-field' type='password' minLength='6' />
                </div>
                <h3 className='forgotPassword'>¿Has olvidado tu contraseña? <a href='/forgot-password'>Haz click aquí</a></h3>
                {error.state ? <ErrorMessage errorMessage={error.text} /> : ''}
                <ButtonCTA isClicked={(e) => auth(e, loginDetails.email, loginDetails.password)} isDisabled={!(isEmailValid && isPasswordValid)}>Entrar</ButtonCTA>


            </form>
        </div>
    )
}

export default LogIn