import React, { useEffect, useState } from "react";
import "./presupuesto.css";
import axios from "axios";

import { baseURL, usuario } from "Constants";

import UrinarioIMG from "Assets/categorias/urinaria.png";
import ReproductivaIMG from "Assets/categorias/reproductiva.png";
import TraumaIMG from "Assets/categorias/trauma.png";
import GastroIMG from "Assets/categorias/gastro.png";
import HepaticaIMG from "Assets/categorias/hepatica.png";
import MinimaIMG from "Assets/categorias/minima.png";
import OcularIMG from "Assets/categorias/ocular.png";
import OralIMG from "Assets/categorias/oral.png";
import PielIMG from "Assets/categorias/piel.png";
import PulmonarIMG from "Assets/categorias/pulmonar.png";
import OncologicaIMG from "Assets/categorias/oncologica.png";
import OidoIMG from "Assets/categorias/oido.png";

import Categoria from "./categoria";
import { DisplayPresupuestoMain } from "UI/displayPresupuesto";
import SearchBar from "UI/searchBar";
import UnAuth from "Pages/unAuth";

const Presupuesto = (props) => {
  const [data, setData] = useState();
  const [current, setCurrent] = useState({});
  const [presupuesto, setPresupuesto] = useState([]);

  const [trauma, setTrauma] = useState(0);

  const [importeTotal, setImporteTotal] = useState(0);

  const scrollPresupuesto = () => {
    const top = document.getElementById("section__title");
    top.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  useEffect(() => {
    axios
      .get(`${baseURL}/cirugias.json`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    let importeT = 0;
    presupuesto.forEach((item) => {
      importeT += parseFloat(item.importe);
    });
    setImporteTotal(importeT);
  }, [presupuesto, presupuesto.length]);

  if (!usuario) {
    return <UnAuth />;
  }

  const handleUpdate = (e, type, trauma) => {
    setCurrent({
      ...current,
      [type]: {
        nombre: e.target.selectedOptions[0].label,
        importe: e.target.value,
        trauma: trauma,
      },
    });
  };

  const handleAdd = (type, isTrauma) => {
    if (type === "urgencias") {
      setPresupuesto(
        presupuesto.concat({
          nombre: "Suplemento Urgencias",
          importe: 200,
          trauma: false,
        })
      );
    } else {
      setPresupuesto(presupuesto.concat([current[type]]));
    }

    if (isTrauma) {
      setTrauma(trauma + 1);
    }

    if (!presupuesto.length) {
      scrollPresupuesto();
    }
  };

  const handleAddBuscar = (nombre, importe, isTrauma) => {
    if (!presupuesto.length) {
      scrollPresupuesto();
    }

    setPresupuesto(
      presupuesto.concat({ nombre: nombre, importe: importe, trauma: isTrauma })
    );
    if (isTrauma) {
      setTrauma(trauma + 1);
    }
  };

  const handleRemove = (index, isTrauma) => {
    const newState = presupuesto;
    newState.splice(index, 1);
    setPresupuesto([...newState]);

    if (isTrauma) {
      setTrauma(trauma - 1);
    }
  };

  const updatePresupuestoAdmin = (e, index) => {
    const newPresupuesto = presupuesto;
    newPresupuesto[index].importe = e.target.value;
    setPresupuesto([...newPresupuesto]);
  };

  return (
    <div className="presupuesto">
      <div className="section__title" id="section__title">
        <h2>Consulta y descarga tu presupuesto</h2>
      </div>

      <div className="presupuesto__container">
        <DisplayPresupuestoMain
          presupuesto={presupuesto}
          importeTotal={importeTotal}
          trauma={trauma}
          loadingState={props.loadingState}
          scrollPresupuesto={scrollPresupuesto}
          handleRemove={handleRemove}
          handleLoading={props.handleLoading}
          updatePresupuestoAdmin={updatePresupuestoAdmin}
        />
        <div className="presupuesto__add-container">
          <div className="presupuesto__add-container__search-container">
            {presupuesto.length ? <p>Añade algo más a tu presupuesto</p> : ""}
            <div className="presupuesto__add-container__search-container__searchbar">
              <label htmlFor="busqueda">Buscar</label>
              <SearchBar data={data} isClicked={handleAddBuscar} />
            </div>
          </div>
          <div className="presupuesto__add-container__categorias">
            <Categoria
              source={OralIMG}
              tipo="oral"
              nombre="Cavidad Oral"
              listado={data ? data.tejidosBlandos.cavidadOral : ""}
              handleOnClick={handleAdd}
              handleOnChange={handleUpdate}
              seleccionado={current}
            />
            <Categoria
              source={ReproductivaIMG}
              tipo="reproductiva"
              nombre="Cirugía reproductiva"
              listado={data ? data.tejidosBlandos.reproductiva : ""}
              handleOnClick={handleAdd}
              handleOnChange={handleUpdate}
              seleccionado={current}
            />
            <Categoria
              source={OncologicaIMG}
              tipo="oncologica"
              nombre="Cirugía oncológica"
              listado={data ? data.tejidosBlandos.oncologica : ""}
              handleOnClick={handleAdd}
              handleOnChange={handleUpdate}
              seleccionado={current}
            />
            <Categoria
              source={PielIMG}
              tipo="piel"
              nombre="Cirugía plástica y reconstructiva"
              listado={data ? data.tejidosBlandos.plastica : ""}
              handleOnClick={handleAdd}
              handleOnChange={handleUpdate}
              seleccionado={current}
            />
            <Categoria
              source={GastroIMG}
              tipo="digestiva"
              nombre="Cirugía gastrointestinal"
              listado={data ? data.tejidosBlandos.gastro : ""}
              handleOnClick={handleAdd}
              handleOnChange={handleUpdate}
              seleccionado={current}
            />
            <Categoria
              source={HepaticaIMG}
              tipo="hepatica"
              nombre="Cirugía hepatobiliar"
              listado={data ? data.tejidosBlandos.hepatobiliar : ""}
              handleOnClick={handleAdd}
              handleOnChange={handleUpdate}
              seleccionado={current}
            />
            <Categoria
              source={UrinarioIMG}
              tipo="urinario"
              nombre="Cirugía del sistema urinario"
              listado={data ? data.tejidosBlandos.sistemaUrinario : ""}
              handleOnClick={handleAdd}
              handleOnChange={handleUpdate}
              seleccionado={current}
            />
            <Categoria
              source={PulmonarIMG}
              tipo="respiratoria"
              nombre="Cirugía del sistema respiratorio"
              listado={data ? data.tejidosBlandos.respiratorio : ""}
              handleOnClick={handleAdd}
              handleOnChange={handleUpdate}
              seleccionado={current}
            />
            <Categoria
              source={OidoIMG}
              tipo="oido"
              nombre="Cirugía del oído"
              listado={data ? data.tejidosBlandos.oido : ""}
              handleOnClick={handleAdd}
              handleOnChange={handleUpdate}
              seleccionado={current}
            />
            <Categoria
              source={OcularIMG}
              tipo="ocular"
              nombre="Cirugía ocular"
              listado={data ? data.tejidosBlandos.ocular : ""} /**Por cambiar */
              handleOnClick={handleAdd}
              handleOnChange={handleUpdate}
              seleccionado={current}
            />
            <Categoria
              source={TraumaIMG}
              tipo="traumatologia"
              nombre="Cirugía ortopédica"
              listado={data ? data.traumatologia : ""}
              handleOnClick={handleAdd}
              handleOnChange={handleUpdate}
              seleccionado={current}
              isTrauma={true}
            />

            <Categoria
              source={MinimaIMG}
              tipo="minima"
              nombre="Mínima invasión"
              listado={data ? data.tejidosBlandos.minima : ""}
              handleOnClick={handleAdd}
              handleOnChange={handleUpdate}
              seleccionado={current}
            />

            <div className="subtotal__container urgencias">
              <p className="subtotal__container__importe urgencias primero">
                Suplemento urgencias &nbsp; 200€
              </p>
              <p className="subtotal__container__importe urgencias">
                IRPF -14€
              </p>
              <p className="subtotal__container__importe urgencias">IVA 42€</p>
              <p className="subtotal__container__importe urgencias total">
                Total 228€
              </p>
              <button
                className="presupuesto__add-container__button urgencias"
                onClick={() => handleAdd("urgencias", false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="ionicon"
                  viewBox="0 0 512 512"
                >
                  <title>Add</title>
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="32"
                    d="M256 112v288M400 256H112"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Presupuesto;
