import React, { useState } from 'react'
import axios from 'axios'
import './admin.css'

import UnAuth from 'Pages/unAuth'
import ManageUsers from './manageUsers'
import VerPresupuesto from './manageUsers/verPresupuesto'

import { ButtonCTA, LinkCTA } from 'Components/buttons'
import { instagramJSON, baseURL, instagramFullPost, usuario } from 'Constants'


const Admin = () => {

    const [adminSelected, setAdminSelected] = useState('portada')
    const [userSelected, setuserSelected] = useState()

    if (!usuario) {
        return <UnAuth />
    }

    if (usuario && !usuario.admin) {
        return <UnAuth admin={true} />
    }


    const updateSelected = (type) => {
        setAdminSelected(type)
    }

    let adminRender

    const getInstagramPhotos = () => {

        const config = {
            mode: 'cors', credentials: 'include'
        }

        axios.get(instagramJSON, config)
            .then(response => {
                axios.put(`${baseURL}instagram.json?auth=${usuario.token}`, response.data.data.user.edge_owner_to_timeline_media.edges)
            })
            .catch(err => alert("No se ha podido actualizar el feed"))
        axios.get(instagramFullPost, config)
            .then((res) => {
                axios.put(`${baseURL}instagramFull.json?auth=${usuario.token}`, res.data.graphql.user.edge_owner_to_timeline_media.edges)
            }).catch((err) => null)
    }



    switch (adminSelected) {
        case 'usuarios':
            adminRender = <ManageUsers callback={() => setAdminSelected("portada")} selectUser={(userName) => {
                setuserSelected(userName)
                setAdminSelected("presupuestos")
            }} />
            break;
        case 'presupuestos':
            adminRender = <VerPresupuesto callback={() => setAdminSelected("usuarios")} userName={userSelected} />
            break;

        case 'portada':
            adminRender =
                <>
                    <div className='section__title'>
                        <h2>Elige una categoría</h2>
                    </div>

                    <div className='admin__form'>
                        <ButtonCTA isClicked={() => updateSelected('usuarios')}>Ver Usuarios</ButtonCTA>
                        <ButtonCTA isClicked={getInstagramPhotos}>Actualizar Feed Instagram</ButtonCTA>
                        <LinkCTA link="https://console.firebase.google.com/u/5/project/vet-and-vet-dc7c6/authentication/users" newTab={true}>Control de usuarios (Firebase)</LinkCTA>
                    </div>
                </>
            break;

        default:
            break;
    }

    return (
        <div className='admin'>

            {adminRender}

        </div>
    )
}

export default Admin