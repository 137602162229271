import React from 'react'
import './categoria.css'

const Categoria = ({ imgSrc, name, list, ...props }) => {

    return (
        <div className="categoria" >
            <div className="categoria__imagen-container" >
                <img src={imgSrc} alt={`Icono ${name}`} />
            </div>
            <div className="categoria__name" >
                {name}
            </div>
            <ul className="categoria__list">
                {list.map((item) => {
                    return <li>{item}</li>
                })}
            </ul>

        </div>
    )
}

export default Categoria