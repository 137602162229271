import React from 'react'
import './botones.css'

export const LinkCTA = ({ link, newTab, ...props }) => {
    if (!newTab) {
        return <a className='linkCTA' href={link} >{props.children}</a>
    } else {
        return <a className='linkCTA' target='_blank' rel="noreferrer" href={link} >{props.children}</a>
    }

}

export const ButtonCTA = ({ isClicked, isDisabled, ...props }) => {
    return (
        <button className='buttonCTA'
            onClick={isClicked} disabled={isDisabled}
        >
            {props.children}
        </button>
    )
}

