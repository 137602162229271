import React, { useState } from 'react'
import './resetEmail.css'

import axios from 'axios'

import { apiKey, usuario } from 'Constants'
import { ButtonCTA } from 'Components/buttons'

const ResetEmail = (props) => {

    const [emailInfo, setEmailInfo] = useState({})

    const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    const isEmailValid = emailInfo.email ? emailPattern.test(emailInfo.email) : false
    const isPasswordValid = emailInfo.password ? emailInfo.password.length >= 6 : false


    const auth = (email, password) => {

        var isSafari = window.safari !== undefined;

        props.handleLoading(true, 'Entrando')

        const timer = new Date()
        timer.setHours(timer.getHours() + 1)
    
        if (isSafari) {

            axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${apiKey}
            `
                // ?nocache=${new Date().getTime()}
                , {
                    email: email,
                    password: password,
                    returnSecureToken: true
                })
                .then(res => {
                    if (res.data.localId === 'lJKWgBfqMjaPVmebXnKJm7EAUCE3') {
                        window.localStorage.setItem('userData', JSON.stringify({ token: res.data.idToken, userId: res.data.localId, userName: res.data.displayName, logoutTimer: timer, email: res.data.email, admin: true }))
                    } else {
                        window.localStorage.setItem('userData', JSON.stringify({ token: res.data.idToken, userId: res.data.localId, userName: res.data.displayName, logoutTimer: timer, email: res.data.email }))
                    }

                    props.handleLoading(false, '')
                   window.location.replace('/perfil')
                })
                .catch(err => {
                    props.handleLoading(false, '')
                })
        } else {
            axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${apiKey}`, {
                email: email,
                password: password,
                returnSecureToken: true
            })
                .then(res => {
                    if (res.data.localId === 'lJKWgBfqMjaPVmebXnKJm7EAUCE3') {
                        window.localStorage.setItem('userData', JSON.stringify({ token: res.data.idToken, userId: res.data.localId, userName: res.data.displayName, logoutTimer: timer, email: res.data.email, admin: true }))
                    } else {
                        window.localStorage.setItem('userData', JSON.stringify({ token: res.data.idToken, userId: res.data.localId, userName: res.data.displayName, logoutTimer: timer, email: res.data.email }))
                    }

                    props.handleLoading(false, '')
                   window.location.replace('/perfil')
                })
                .catch(err => {

                    props.handleLoading(false, '')

                })
        }
    }



    const handleUpdate = (e, type) => {
        setEmailInfo({ ...emailInfo, [type]: e.target.value })
    }

    const updateEmail = e => {
        props.handleLoading(true, 'Cambiando Email')
        e.preventDefault()
        axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${apiKey}`, {
            email: usuario.email,
            password: emailInfo.password,
            returnSecureToken: true
        })
        .then(res => {
            axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:update?key=${apiKey}`,{
                idToken: res.data.idToken,
                email: emailInfo.email,
                returnSecureToken: true
            })
            .then(resp => {
                auth(emailInfo.email, emailInfo.password)
            })
        })
    }

    return (
        <>
            <div className='reset-password'>
                <div className='reset-password__input-container'>
                    <label htmlFor='nuevoEmail'>Nuevo Email</label>
                    <input onChange={(e) => handleUpdate(e, 'email')} id='nuevoEmail' type='email' />
                    <label htmlFor='passwordCurrent'>Confirma tu contraseña</label>
                    <input onChange={(e) => handleUpdate(e, 'password')} id='passwordCurrent' type='password' />
                </div>
                <div className='reset-password__button-container'>
                    <ButtonCTA isClicked={props.clickPortada}>Volver</ButtonCTA>
                    <ButtonCTA isClicked={(e) => updateEmail(e)} isDisabled={!(isEmailValid && isPasswordValid)}>Actualizar Email</ButtonCTA>

                </div>
            </div>

        </>
    )
}

export default ResetEmail