import React from 'react'
import './modal.css'

const modal = (props) => {

    return (
        <div className='backdrop'>
            <div className='modal'>
                {props.children}
            </div>
        </div>
    )
}

export default modal